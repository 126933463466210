import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { Respuesta } from '../models/Respuesta';
import { Establecimiento } from '../models/establecimiento';
import { EstablecimientoResult } from '../models/EstablecimientoResult';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { MesaResult } from '../models/mesa-result';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  constructor(private http: HttpClient, private storageService: StorageService ) { }
  
  public url: string = environment.baseUrl;  
  headers = new HttpHeaders().set('Content-Type','application/json');

  private strToken=this.storageService.getCurrentToken();

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer ' + this.strToken  })
  };

  public appDrawer: any;

  getMesas(cookie,mesa):Observable<MesaResult>{
    let dato='strCookie='+cookie+'&intMesaId='+mesa;
    let urlCompleta=this.url+'Mesa/detalle?'+dato;
 
    return this.http.get<MesaResult>(urlCompleta, this.httpOptions);
  }

  getEstablecimiento(cookie):Observable<EstablecimientoResult>{ 
    let dato='strCookie='+cookie;
    let urlCompleta=this.url+'establecimiento/general/detalle?'+dato;
 
    return this.http.get<EstablecimientoResult>(urlCompleta,  this.httpOptions);
  }

  getPoliticaCookies(cookie):Observable<any>{ 
    let dato='strMiCookie='+cookie;
    let urlCompleta=this.url+'establecimiento?'+dato;
 
    return this.http.get<any>(urlCompleta,  this.httpOptions);
  }

  verificaTicket(cookie,intMesaId,intNTicket):Observable<Respuesta>{ 
    let dato='strCookie='+cookie+'&intMesaId='+intMesaId+'&intNTicket='+intNTicket;
    let urlCompleta=this.url+'SolicitaFactura?'+dato;
 
    return this.http.get<Respuesta>(urlCompleta,  this.httpOptions);
  }

  insertaCliente(cookie,intNTicket,cliente):Observable<Respuesta>{ 
    let dato='strCookie='+cookie+'&intNTicket='+intNTicket;
    let urlCompleta=this.url+'SolicitaFactura?'+dato;
    var body=JSON.stringify(cliente)
 
    return this.http.post<Respuesta>(urlCompleta, body, this.httpOptions);
  }


  /********** Exportar a EXCEL *************/

  exportAsExcelFile(json: any[], excelFileName: string): void {
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  /********** Menu, Sub items *************/

  public closeNav() {
    this.appDrawer.close();
  }

  public openNav() {
    this.appDrawer.open();
  }

}
