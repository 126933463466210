import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';

@Component({
  selector: 'app-ventana-emergente',
  templateUrl: './ventana-emergente.component.html',
  styleUrls: ['./ventana-emergente.component.scss']
})
export class VentanaEmergenteComponent implements OnInit {

  dosAcciones=false;
  muestraOk=false;
  muestraError=false;

  constructor(private thisDialogRef: MatDialogRef<MatDialog>,@Inject(MAT_DIALOG_DATA) public data: miEstructura) { }

  ngOnInit(){
    this.dosAcciones=this.data.boton;
    if(this.data.btnError){
      this.muestraError=true;
      // this.muestraOk=false;
    }
    // else{
    //   this.muestraError=false;
    //   // this.muestraOk=true;
    // }

    if(this.data.btnOK){
      this.muestraOk=true;
    }
  }

  pulsa(){
    this.thisDialogRef.close('delete');
  }

  pulsa2(){
    this.thisDialogRef.close();
  }

}

export interface miEstructura {
  texto:any;
  texto2:any;
  boton:any;
  btnError:any;
  btnOK:any;
}