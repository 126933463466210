import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-politica-cookies',
  templateUrl: './politica-cookies.component.html',
  styleUrls: ['./politica-cookies.component.scss']
})
export class PoliticaCookiesComponent implements OnInit {

  strPoliticaCookies:string;

  constructor(private thisDialogRef: MatDialogRef<MatDialog>,@Inject(MAT_DIALOG_DATA) public data: any, private miservicio:RestService) { }

  ngOnInit() {
    // this.verPoliticaCookies();
    // console.log(this.data)
    this.strPoliticaCookies=this.data;
  }

  // verPoliticaCookies(){
  //   this.miservicio.getPoliticaCookies(this.data).subscribe(datos=>{
  //     // console.log(datos)
  //     // if(datos.miRespuesta.booOk){
  //     //   this.strPoliticaCookies=datos.miListEstablecimiento[0].strPoliticaCookies;
  //     // }else{
  //     //   this.strPoliticaCookies=datos.miRespuesta.strMensaje;
  //     // }
  //     this.strPoliticaCookies=datos.strPoliticaCookies;
  //   })
  // }

  aceptar(){
    this.thisDialogRef.close(true);
  }

  cancelar(){
    this.thisDialogRef.close(false);
  }

}
