import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/services/rest.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { MatDialog } from '@angular/material';
import { CookieService } from 'ngx-cookie-service';
import { DataCookie } from 'src/app/models/data-cookie';
import { Establecimiento } from 'src/app/models/establecimiento';
import { environment } from 'src/environments/environment';
import { FormControl, Validators } from '@angular/forms';
import { VentanaEmergenteComponent } from '../ventana-emergente/ventana-emergente.component';

@Component({
  selector: 'app-factura',
  templateUrl: './factura.component.html',
  styleUrls: ['./factura.component.scss']
})
export class FacturaComponent implements OnInit {

  idEstablecimiento:string="";
  midataCookie:DataCookie=new DataCookie();
  miEstablecimiento:Establecimiento= new Establecimiento();

  cookieNombre:string=environment.cookieNombre;
  MesaId:string=environment.intIdMesa;

  muestraContenido=false;
  myLoader:boolean=false;
  strMesa:string;
  strZona:string;

  colorCuerpo:string;
  colorMenu:string;
  colorTextoCuerpo:string;
  colorTextoMenu:string;

  NTicket = new FormControl('', [Validators.required]);
  ntikect:string;

  constructor(private miservicio:RestService,private route: ActivatedRoute,private router: Router,public dialog: MatDialog,private cookieService: CookieService) {
    this.route.queryParams.subscribe(params => {
      this.idEstablecimiento=params["id"];
    });
   }

  ngOnInit() {
    
    this.myLoader=true;

    this.midataCookie=JSON.parse(this.cookieService.get(this.cookieNombre+this.idEstablecimiento));
    // console.log(this.midataCookie)
    setTimeout(()=>{
      this.colorCuerpo=this.midataCookie.strColorCuerpo;
      this.colorTextoCuerpo=this.midataCookie.strColorTextoCuerpo;
      this.strMesa=this.midataCookie.nombreMesa;
      this.strZona=this.midataCookie.nombreZona;
      this.getDatosEst();
    }, 500)
  }

  getErrorMessage() {
    if (this.NTicket.hasError('required')) {
      return 'Por favor indique un Nº de ticket';
    }

    // return this.NTicket.hasError('NTicket') ? 'Not a valid email' : '';
  }

  getDatosEst(){
    this.miservicio.getEstablecimiento(this.midataCookie.coo).subscribe(datos=>{
      // console.log(datos)
      this.myLoader=false;
      this.muestraContenido=true;
      if(datos.miRespuesta.booOk){
        this.miEstablecimiento=datos.miListEstablecimiento[0];

        if(this.miEstablecimiento.strTelefono1!=null && this.miEstablecimiento.strTelefono1!=""){
          this.miEstablecimiento.strTelefono1=" - " + this.miEstablecimiento.strTelefono1;
        }
        if(this.miEstablecimiento.strTelefono2!=null && this.miEstablecimiento.strTelefono2!=""){
          this.miEstablecimiento.strTelefono2=" - " + this.miEstablecimiento.strTelefono2;
        }
        if(this.miEstablecimiento.strDireccion!=null && this.miEstablecimiento.strDireccion!=""){
          this.miEstablecimiento.strDireccion=" - " + this.miEstablecimiento.strDireccion;
        }
        if(this.miEstablecimiento.strPoblacion!=null && this.miEstablecimiento.strPoblacion!=""){
          this.miEstablecimiento.strPoblacion=" - " + this.miEstablecimiento.strPoblacion;
        }
        if(this.miEstablecimiento.strCp!=null && this.miEstablecimiento.strCp!=""){
          this.miEstablecimiento.strCp=" - " + this.miEstablecimiento.strCp;
        }
        if(this.miEstablecimiento.strProvincia!=null && this.miEstablecimiento.strProvincia!=""){
          this.miEstablecimiento.strProvincia=" - " + this.miEstablecimiento.strProvincia;
        }
      }
    })
  }

  guardaTicket(){
    if(this.NTicket.valid){
      this.myLoader=true;
      this.miservicio.verificaTicket(this.midataCookie.coo,parseInt(this.midataCookie.mes),parseInt(this.ntikect)).subscribe(datos=>{
        // console.log(datos)
        this.myLoader=false;
        if(datos.booOk){
          //ok
          let extra: NavigationExtras = {
            queryParams: {
              'id':this.idEstablecimiento,
              'ticket':this.ntikect
            }
          }
          this.router.navigate(['formulario'], extra)
        }else{
          this.windowNotif(false,true,false,datos.strMensaje,"Por favor vuelva a intentarlo.")
        }
      })
    }
  }

  windowNotif(booBoton, booBtnError, btnOK, strMensaje, strParrafo2){
    let dialogRef = this.dialog.open(VentanaEmergenteComponent, {
      width: '600px',
      data:{
        texto:strMensaje,
        texto2:strParrafo2,
        boton:booBoton,
        btnError:booBtnError,
        btnOK:btnOK
      }
    });
    dialogRef.afterClosed().subscribe(result =>{
      return result;
    })
  }

}
