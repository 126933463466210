import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { MatDialog } from '@angular/material';
import { PoliticaCookiesComponent } from '../politica-cookies/politica-cookies.component';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import { VentanaEmergenteComponent } from '../ventana-emergente/ventana-emergente.component';
import { DataCookie } from 'src/app/models/data-cookie';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  cookieNavegador = environment.cookieNombreNavegador;
  cookieNombre = environment.cookieNombre;
  aceptCookies:boolean=true;
  IsCookieExists:boolean=this.cookieService.check(this.cookieNavegador);
  idEstablecimiento:string="";
  midataCookie:DataCookie=new DataCookie();
  strPoliticaCookies:string;

  constructor(private cookieService: CookieService,private dialog: MatDialog, private route:ActivatedRoute, private miservicio:RestService) {
    this.route.queryParams.subscribe(params => {
      this.idEstablecimiento=params["id"];
    });
  }

  ngOnInit() {
    // console.log(this.idEstablecimiento)
    this.midataCookie=JSON.parse(this.cookieService.get(this.cookieNombre+this.idEstablecimiento));

    if(this.IsCookieExists){
      this.aceptCookies=false;
    }

    setTimeout(()=>{
      this.solicitaPoliticaCookies();
    },1000)
  }

  solicitaPoliticaCookies(){
    this.miservicio.getPoliticaCookies(this.midataCookie.coo).subscribe(datos=>{
      this.strPoliticaCookies=datos.strPoliticaCookies;
    })
  }

  cancelar(){
    this.aceptCookies=false;
  }

  aceptaCookies(){
    this.cookieService.set(this.cookieNavegador,this.cookieNavegador,365,'',window.location.hostname);
    this.aceptCookies=false;
  }

  cookies(){
    let dialogRef = this.dialog.open(PoliticaCookiesComponent, {
      width:'600px',
      data:this.strPoliticaCookies
    });
    dialogRef.afterClosed().subscribe(result =>{
      if(result){
        this.cookieService.set(this.cookieNavegador,this.cookieNavegador,365,'',window.location.hostname);
        this.aceptCookies=false;
      }
    })
  }

}
