export class DataCookie {
    public coo?:any;
    public est?:any;
    public pro?:any;
    public mes?:any;
    public nombreMesa?:any;
    public nombreZona?:any;
    public strColorCuerpo?:any;
    public strColorTextoCuerpo?:any;
}
