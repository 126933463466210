import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RestService } from './services/rest.service';
import { AppMaterialModule } from './app-material.module';
// import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { StorageService } from './services/storage.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule, MatPaginatorIntl } from '@angular/material';
import { CustomPaginator } from './resources/Funciones';
import { DatePipe } from '@angular/common';
import { VentanaEmergenteComponent } from './components/ventana-emergente/ventana-emergente.component';
import { FooterComponent } from './components/footer/footer.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { FacturaComponent } from './components/factura/factura.component';
import { PoliticaCookiesComponent } from './components/politica-cookies/politica-cookies.component';
import { FormularioComponent } from './components/formulario/formulario.component';
// import {MatDialogModule} from '@angular/material/dialog';

@NgModule({
  declarations: [
    AppComponent,
    VentanaEmergenteComponent,
    PoliticaCookiesComponent,
    FooterComponent,
    InicioComponent,
    FacturaComponent,
    FormularioComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModule,
    // HttpModule,
    HttpClientModule,
    BrowserAnimationsModule,
    // MatDialogModule,
    MatNativeDateModule
  ],
  providers: [RestService, StorageService, {provide: MatPaginatorIntl, useValue: CustomPaginator() },DatePipe
              
             ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
