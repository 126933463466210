import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { RestService } from 'src/app/services/rest.service';
import { environment } from 'src/environments/environment';
import { DataCookie } from 'src/app/models/data-cookie';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  cookie:string;
  mesa:string;
  idEstablecimiento:string="";
  idProducto:string="";

  showInfi=false;
  showError=false;
  strMensajeError:string="";
  booContinuar=false;
  booContinuar2=false;

  cookieNombre:string=environment.cookieNombre;
  MesaId:string=environment.intIdMesa;

  midataCookie:DataCookie=new DataCookie();

  constructor(private router: Router,private route: ActivatedRoute, private miservicio:RestService, private cookieService: CookieService) {
    this.route.queryParams.subscribe(params => {
      this.cookie=params["coo"];
      this.mesa=params["mes"];
      this.idEstablecimiento=params["est"];
      this.idProducto=params["pro"];
    });
   }

  ngOnInit() {
    if(this.cookie!=undefined && this.cookie!=""){
      this.booContinuar=true;
    }else{
      this.booContinuar=false;
      this.strMensajeError += "Parece que se ha producido un error, asegurese de que tiene la cookie.Si no es así, vuelva a escanear el codigo QR.";
    }

    if(this.mesa!=undefined && this.mesa!=""){
      this.booContinuar2=true;
    }else{
      this.booContinuar2=false;
      this.strMensajeError+=" No se ha encontrado la mesa";
    }

    setTimeout(() => {
      if(this.booContinuar && this.booContinuar2){
        this.showError=false;
        this.showInfi=true;
        // console.log(this.cookie+" // " + this.mesa)
        this.getEstablecimiento();
      }else{
        this.showError=true;
        this.showInfi=false;
      }
    }, 1000);
  }

  @HostListener('window:beforeunload', ['$event']) beforeUnloadHander(event) {
    sessionStorage.clear();
    // console.log("close")
  }

  getEstablecimiento(){
    this.miservicio.getMesas(this.cookie,this.mesa).subscribe(data=>{
      // console.log(data)

      if(data.miRespuesta.booOk){

        this.miservicio.getEstablecimiento(this.cookie).subscribe(datos=>{
          // console.log(datos)

          if(datos.miRespuesta.booOk){

            let IsCookieExists:boolean=this.cookieService.check(this.cookieNombre+this.idEstablecimiento);
            if(!IsCookieExists){
              this.midataCookie.coo=this.cookie;
              this.midataCookie.est=this.idEstablecimiento;
              this.midataCookie.mes=this.mesa;
              this.midataCookie.pro=this.idProducto;
              this.midataCookie.nombreMesa=data.miListMesa[0].strNombre;
              this.midataCookie.nombreZona=data.miListMesa[0].strZonaNombre;
              this.midataCookie.strColorCuerpo = "#"+datos.miListEstablecimiento[0].strColorCuerpo;
              this.midataCookie.strColorTextoCuerpo="#"+datos.miListEstablecimiento[0].strColorTextoCuerpo;

              this.cookieService.set(this.cookieNombre+this.idEstablecimiento,JSON.stringify(this.midataCookie),4,'',window.location.hostname);

            }else{
              this.removeCookies();

              //Vuelvo a crear la cookie
              this.midataCookie.coo=this.cookie;
              this.midataCookie.est=this.idEstablecimiento;
              this.midataCookie.mes=this.mesa;
              this.midataCookie.pro=this.idProducto;
              this.midataCookie.nombreMesa=data.miListMesa[0].strNombre;
              this.midataCookie.nombreZona=data.miListMesa[0].strZonaNombre;
              this.midataCookie.strColorCuerpo = "#"+datos.miListEstablecimiento[0].strColorCuerpo
              this.midataCookie.strColorTextoCuerpo="#"+datos.miListEstablecimiento[0].strColorTextoCuerpo

              this.cookieService.set(this.cookieNombre+this.idEstablecimiento,JSON.stringify(this.midataCookie),4,'',window.location.hostname);
            }

            setTimeout(() => {
              let extra: NavigationExtras = {
                queryParams: {
                  'id':this.idEstablecimiento
                }
              }
              this.router.navigate(['/factura'], extra);
            }, 1000);  

          }else{
            this.showError=true;
            this.showInfi=false;
            this.strMensajeError=data.miRespuesta.strMensaje;
          }
        })
      }else{
        this.showError=true;
        this.showInfi=false;
        this.strMensajeError=data.miRespuesta.strMensaje;
      }
    })
  }

  Aceptar(){
    window.history.back();
  }

  removeCookies(){
    this.cookieService.delete(this.cookieNombre+this.idEstablecimiento,'',window.location.hostname);
  }

}
