// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //baseUrl: 'https://webservice.infitec.es/resttpvwa/api/',
  // baseUrl:'http://localhost:35113/api/',
  baseUrl : 'https://resttpvwa.infitec.es/api/',

  //Variables cookies
  cookieNombre:"CookieFacturaOnline",
  cookieNombreNavegador:"CookieFacturaOnlineNavegador",
  intIdMesa:"intMesaIdFacturaOnline",
  strColorCuerpo:"strColorCuerpoFacturaOnline",
  strColorTextoCuerpo:"strColorTextoCuerpoFacturaOnline",
  strNombreMesa:"strMesaFacturaOnline",
  strNombreZona:"strZonaFacturaOnline",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
