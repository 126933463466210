export class Cliente {
    public intId?:number;
    public strNombre?:string;
    public strApellidos?:string;
    public strDni?:string;
    public strEmail?:string;
    public strDireccion?:string;
    public strCP?:string;
    public strPoblacion?:string;
    public strProvincia?:string;
    public strPais?:string;
    public strTel1?:string;
    public strTel2?:string;
    public strActivo?:string;
    public strUserId?:string;
    public strTipoCliente?:string;
    public strPassword?:string;
    public strUserName?:string;
    public intIdExterno?:number;
}
