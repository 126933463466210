import { Component, OnInit } from '@angular/core';
import { DataCookie } from 'src/app/models/data-cookie';
import { Establecimiento } from 'src/app/models/establecimiento';
import { environment } from 'src/environments/environment';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { RestService } from 'src/app/services/rest.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { MatDialog } from '@angular/material';
import { CookieService } from 'ngx-cookie-service';
import { VentanaEmergenteComponent } from '../ventana-emergente/ventana-emergente.component';
import { Cliente } from 'src/app/models/cliente';

@Component({
  selector: 'app-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.scss']
})
export class FormularioComponent implements OnInit {

  idEstablecimiento:string="";
  midataCookie:DataCookie=new DataCookie();
  miEstablecimiento:Establecimiento= new Establecimiento();

  cookieNombre:string=environment.cookieNombre;
  MesaId:string=environment.intIdMesa;

  muestraContenido=false;
  myLoader:boolean=false;
  strMesa:string;
  strZona:string;

  colorCuerpo:string;
  colorMenu:string;
  colorTextoCuerpo:string;
  colorTextoMenu:string;

  clienteForm: FormGroup;
  submitted=false;
  cliente:Cliente=new Cliente();
  nticket:string;

  constructor(private miservicio:RestService,private route: ActivatedRoute,private router: Router,public dialog: MatDialog,private cookieService: CookieService,
              private formBuilder: FormBuilder) {
    this.route.queryParams.subscribe(params => {
      this.idEstablecimiento=params["id"];
      this.nticket=params["ticket"];
    });
   }

  ngOnInit() {
    this.myLoader=true;

    this.midataCookie=JSON.parse(this.cookieService.get(this.cookieNombre+this.idEstablecimiento));
    // console.log(this.midataCookie)
    setTimeout(()=>{
      this.colorCuerpo=this.midataCookie.strColorCuerpo;
      this.colorTextoCuerpo=this.midataCookie.strColorTextoCuerpo;
      this.strMesa=this.midataCookie.nombreMesa;
      this.strZona=this.midataCookie.nombreZona;
      this.getDatosEst();
    }, 500)

    this.clienteForm  = this.formBuilder.group({

      nombre: new FormControl('', Validators.compose([
        Validators.required,
        Validators.maxLength(40),
        //Validators.pattern('[a-zA-Z]*$'),
      ])),

      apellidos: new FormControl('', Validators.compose([
        Validators.maxLength(40),
        //Validators.pattern('[a-zA-Z]*$')
      ])),

      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        //Validators.maxLength(5)
      ])),

      dni: new FormControl('', Validators.compose([
        Validators.required,
      ])),
     
      direccion: new FormControl('', Validators.compose([
        Validators.maxLength(40),
      ])),

      poblacion: new FormControl('', Validators.compose([
        Validators.maxLength(40),
      ])),

      provincia: new FormControl('', Validators.compose([
        Validators.maxLength(40),
      ])),

      cp: new FormControl('', Validators.compose([
        Validators.maxLength(5),
      ]))
    });

  }

  getDatosEst(){
    this.miservicio.getEstablecimiento(this.midataCookie.coo).subscribe(datos=>{
      // console.log(datos)
      this.myLoader=false;
      this.muestraContenido=true;
      if(datos.miRespuesta.booOk){
        this.miEstablecimiento=datos.miListEstablecimiento[0];

        if(this.miEstablecimiento.strTelefono1!=null && this.miEstablecimiento.strTelefono1!=""){
          this.miEstablecimiento.strTelefono1=" - " + this.miEstablecimiento.strTelefono1;
        }
        if(this.miEstablecimiento.strTelefono2!=null && this.miEstablecimiento.strTelefono2!=""){
          this.miEstablecimiento.strTelefono2=" - " + this.miEstablecimiento.strTelefono2;
        }
        if(this.miEstablecimiento.strDireccion!=null && this.miEstablecimiento.strDireccion!=""){
          this.miEstablecimiento.strDireccion=" - " + this.miEstablecimiento.strDireccion;
        }
        if(this.miEstablecimiento.strPoblacion!=null && this.miEstablecimiento.strPoblacion!=""){
          this.miEstablecimiento.strPoblacion=" - " + this.miEstablecimiento.strPoblacion;
        }
        if(this.miEstablecimiento.strCp!=null && this.miEstablecimiento.strCp!=""){
          this.miEstablecimiento.strCp=" - " + this.miEstablecimiento.strCp;
        }
        if(this.miEstablecimiento.strProvincia!=null && this.miEstablecimiento.strProvincia!=""){
          this.miEstablecimiento.strProvincia=" - " + this.miEstablecimiento.strProvincia;
        }
      }
    })
  }

  insertaCliente(){
    this.submitted = true;
    if(this.clienteForm.valid){
      this.myLoader=true;
      // console.log(this.cliente)
      this.miservicio.insertaCliente(this.midataCookie.coo,this.nticket,this.cliente).subscribe(datos=>{
        // console.log(datos)
        this.myLoader=false;
        if(datos.booOk){
          this.windowNotif(false,false,true,"En breves recibirá un email con su Factura.","");
        }else{
          this.windowNotif(false,true,false,datos.strMensaje,"");
        }
      })
    }
  }

  windowNotif(booBoton, booBtnError, btnOK, strMensaje, strParrafo2){
    let dialogRef = this.dialog.open(VentanaEmergenteComponent, {
      width: '600px',
      data:{
        texto:strMensaje,
        texto2:strParrafo2,
        boton:booBoton,
        btnError:booBtnError,
        btnOK:btnOK
      }
    });
    dialogRef.afterClosed().subscribe(result =>{
      return result;
    })
  }

}
