import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VentanaEmergenteComponent } from './components/ventana-emergente/ventana-emergente.component';
import { AuthAdminGuard } from './guards/authAdminGuard';
import { AuthGuard } from './guards/authGuard';
import { PoliticaCookiesComponent } from './components/politica-cookies/politica-cookies.component';
import { FooterComponent } from './components/footer/footer.component';
import { FacturaComponent } from './components/factura/factura.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { FormularioComponent } from './components/formulario/formulario.component';


const routes: Routes = [
  /** LISTA DE GENERAL DE COMPONENTES **/

  { path: 'ventana-emergente', component: VentanaEmergenteComponent },
  { path: 'politica-cookies', component: PoliticaCookiesComponent },
  { path: 'footer', component: FooterComponent },
  { path: 'inicio', component: InicioComponent },
  { path: 'factura', component: FacturaComponent },
  { path: 'formulario', component: FormularioComponent },

  { path: '', redirectTo: '/factura', pathMatch: 'full' },
  { path: '**', redirectTo: '/factura', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
