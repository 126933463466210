
import {Injectable} from "@angular/core";
import { Router, NavigationExtras } from '@angular/router';
import { Sesion } from '../models/Sesion';
import { User } from '../models/User';
import { SesionPagos } from '../models/sesion-pagos';

@Injectable()
export class StorageService {

  private localStorageService;
  private currentSession : Sesion = null;

  constructor(private router: Router) {
    this.localStorageService = localStorage;
    this.currentSession = this.loadSessionData();
  }

  setCurrentSession(session: Sesion): void {
    this.currentSession = session;
    this.localStorageService.setItem('cookieGestionWeb', JSON.stringify(session));
  }

  loadSessionData(): Sesion{
    var sessionStr = this.localStorageService.getItem('cookieGestionWeb');
    return (sessionStr) ? <Sesion> JSON.parse(sessionStr) : null;
  }

  getCurrentSession(): Sesion {
    return this.currentSession;
  }

  removeCurrentSession(): void {
    this.localStorageService.removeItem('cookieGestionWeb');
    this.currentSession = null;
  }

  getCurrentUser(): User {
    var session: Sesion = this.getCurrentSession();
    return (session && session.user) ? session.user : null;
  };

  isAuthenticated(): boolean {
    return (this.getCurrentToken() != null) ? true : false;
  };

  getCurrentToken(): string {
    var session = this.getCurrentSession();
    return (session && session.miToken.access_token) ? session.miToken.access_token : null;
  };

  logout(): void{
    window.location.href='http://infiteccontrol.infitec.es/servicios/solicita?est='+ this.getCurrentSession().strEstablecimientoId +'&pro='+  this.getCurrentSession().strProductoId+'';



    this.removeCurrentSession();
  }

}
